.myInput {
    width: 100%;
    padding: 5px 5px;
    margin: 5px 0;
    border-bottom: 1px solid gray;
    font-size: 18px;
  }

textarea:focus, input:focus{
    outline: none;
  }