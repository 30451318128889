.myInput {
    width: 100%;
    padding: 5px 15px;
    margin: 5px 0;
    border: 1px solid #F26101;
    border-radius: 2px;
  }

.inputFile {
	position: relative;
	display: inline-block;
}

.inputFileBtn {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 18px;
	vertical-align: middle;
	text-align: center;
    align-content: center;
	border-radius: 2px;
	background-color: white;
	line-height: 22px;
	height: 80px;
	padding: 26px 20px;
	box-sizing: border-box;
	border: none;
	margin: auto 0;
	transition: background-color 0.2s;
    width: 200px;
    border: 1px solid gray;;
}
.inputFileText {
	padding: 0 10px;
	line-height: 40px;
	display: inline-block;
}

.inputFile input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
 
/* Focus */
.inputFile input[type=file]:focus + .inputFileBtn {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
 
/* Hover/active */
.inputFile:hover .inputFileBtn {
	background-color: #E1E1E1;
}
