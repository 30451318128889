.myBtn {
    padding: 10px 25px;
    color: white;
    font-size: 18px;
    font-family: "Montserrat", serif ;
    background: #F26101;
    border: 2px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
    max-width: 250px;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.myBtn:hover, .myBtn.active {
    color: white;
    background-color: #D95701;
    transition: 0.3s;
}

.myBtn:disabled {
    padding: 10px 25px;
    color: white;
    font-size: 18px;
    background: rgba(157, 157, 157, 0.4);
    border: 2px solid #D95701;
    border-radius: 10px;
    cursor: auto;
    transition: 0.3s;
    max-width: 250px;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

@media (max-width: 480px) {
    .myBtn {
        font-size: 14px;
        padding: 8px 20px
    }
}