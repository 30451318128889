body {
  margin: 0;
  font-family: "Montserrat", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  font-family: "Montserrat", serif;
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
  font-family: "Montserrat", serif;
  margin: 0;
  padding: 0;
}

.height-need {
  height: 100%;
  font-family: "Montserrat", serif;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

img {
  vertical-align: top;
}

html,
body {
  height: 100%;
  font-family: "Montserrat", serif;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.header_burger {
  margin: 27px 0 0 36px;
}

.sidenav {
  height: 100%;
  width: 100px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #304269;
  overflow-x: hidden;
  padding: 25px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.sidenav img {
  max-width: 50px;
}

.main {
  margin-left: 100px; /* Same as the width of the sidenav */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 10px;
}

.main2 {
  margin-left: 100px; /* Same as the width of the sidenav */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 10px;
  
}

.menu-block {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.header_burger {
  display: none;
}

@media (max-width: 1023px) {
  /*.sidenav {*/
  /*	width: 75px;*/
  /*	padding: 12px;*/
  /*}*/
  /*.sidenav img {*/
  /*	max-width: 40px;*/
  /*}*/

  /*.main {*/
  /*margin-left: 75px; !* Same as the width of the sidenav *!*/
  /*}*/

  /*.menu-block {*/
  /*gap: 30px;*/
  /*}*/

  .sidenav {
    left: -100%;
    padding: 75px 25px 25px 25px;
    transition: all 0.5s ease 0s;
  }

  .sidenav.active {
    height: 100%;
    width: 100px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    transition: all 0.5s ease 0s;
    background-color: #304269;
    overflow-x: hidden;
    padding: 75px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }

  .header_burger {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 3;
  }

  body.lock {
    overflow: hidden;
  }

  .header_burger span {
    position: absolute;
    background-color: #304269;
    left: 0;
    width: 100%;
    height: 2px;
    top: 9px;
    transition: all 0.3s ease 0s;
  }
  .header_burger:before,
  .header_burger:after {
    content: "";
    background-color: #304269;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease 0s;
  }
  .header_burger:before {
    top: 0;
  }
  .header_burger:after {
    bottom: 0;
  }

  .header_burger.active span {
    transform: scale(0);
    background-color: white;
  }
  .header_burger.active:before {
    transform: rotate(45deg);
    background-color: white;
    top: 9px;
  }
  .header_burger.active:after {
    transform: rotate(-45deg);
    background-color: white;
    bottom: 9px;
  }

  .main {
    margin: 10px;
  }
}

@media (max-width: 767px) {
  .header_burger {
    margin: 27px 0 0 15px;
  }

  .sidenav {
    width: 60px;
    left: -100%;
    padding: 75px 10px 25px 10px;
    transition: all 0.5s ease 0s;
  }

  .sidenav img {
    max-width: 40px;
  }

  .sidenav.active {
    height: 100%;
    width: 60px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    transition: all 0.5s ease 0s;
    background-color: #304269;
    overflow-x: hidden;
    padding: 75px 10px 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }

  .sidenav.active img {
    max-width: 40px;
  }
}

/* --------------------------------File Upload Component--------------------------------- */

.application-useful-block {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  margin: 1rem;
  align-items: center;
  flex: 100%;
  justify-content: center;
}

.application-useful-block h1 {
  font-size: 30px;
  margin: 0 0 0.5rem 0;
}

@media (max-width: 964px) {
  .application-useful-block {
    max-width: 700px;
    width: auto;
  }
}

.file-upload-form {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.file-queue-form {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.two-sides-flex {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1600px;
}

.centered-two-sides {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: white;
  max-width: 400px;
  min-width: 300px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
}

.left-login-block {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 300px;
}

@media (max-width: 1400px) {
  .sides-flex-stats {
    flex-direction: column !important;
  }
}



.vertical-even-two-flex {
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
  min-height: 100%;
}

.vertical-even-one-flex {
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
}

@media (max-width: 1023px) {
  .two-sides-flex {
    flex-direction: column;
  }
}

/* ----------------------------- Toast ----------------------------- */

.wrap-enter {
  opacity: 0;
  transform: scale(0.9);
}
.wrap-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.wrap-exit-done {
  display: none;
}
.wrap-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 1300ms, transform 300ms;
}

/* ----------------------------- Stats ----------------------------- */

.stats-settings-block {
  flex: 0 1 30%;
  display: flex;
  flex-direction: column;
}

.stats-charts-block {
  flex: 0 1 70%;
  display: flex;
  flex-direction: column;
}

.file-filter-form {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.charts-buttons-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.charts-block {
  margin: 2rem;
  min-width: 800px;
  display: flex;
}

@media (max-width: 1400px) {
  .charts-block {
    width: 400px;
  }
}

@media (max-width: 1140px) {
  .charts-block {
    margin: 1rem;
    min-width: 300px;
    min-height: 330px;
  }
}

@media (max-width: 1023px) {
  .charts-block {
    width: 420px;
  }
}

@media (max-width: 863px) {
  .charts-block {
    margin: 0.5rem;
    min-width: 200px;
    min-height: 230px;
  }
}

@media (max-width: 524px) {
  .charts-block {
    max-width: 270px;
    max-height: 300px;
  }
}

.chart-container {
  width: 100%;
  height: 500px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart-container canvas{
  align-self: center;
}

.three-buttons-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.three-buttons-block button{
  width: 250px;
}

progress::-webkit-progress-value {
  background: #030204;
}

/* ---------------- File List* ------------------ */

.file-list-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.file-list-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 1rem;
}

.one-file-body {
  background-color: rgba(255, 106, 43, 0.2);
  border-radius: 10px;
  width: 100%;
  border: 1px solid black;
  max-width: 455px;
}

.one-file-table {
  width: 100%;
  margin: 0.5rem 0;
}

.row-name {
  font-weight: 800;
  text-align: left;
  width: 26%;
  padding: 5px 10px;
  margin: 20px;
}

.row-value {
  width: 64%;
  text-align: center;
}

.one-file-table {
  border-collapse: collapse;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

/* ---------------- Pagination ------------------ */

.pagination {
  display: inline-block;
}

.page-link {
  color: black;
  font-size: 20px;
  float: left;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.3s;
  user-select: none;
}

.page-link.active {
  background-color: #4caf50;
  color: white;
}

.page-link:hover:not(.active) {
  background-color: #ddd;
  cursor: pointer;
}

.page-link-current {
  background-color: #ddd;
}

/* ------------- File Search ------------------- */

.file-search-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1rem
}

.search-icon {
  cursor: pointer;
}



















/* ------------ Toast ----------- */


.alertmessageold {
  color: white;
  z-index: 80;
  padding: 1rem 1rem;
  position: fixed !important;
  width: 360px;
  border-radius: 2px;
  background-color: #304269;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  opacity: 0;
}

.alertmessage-enter-active {
  opacity: 0;

}

.alertmessage-enter-done {
  opacity: 1;
  transition: opacity .5s;
}

.alertmessage-exit {
  opacity: 1;
}

.alertmessage-exit-active {
  opacity: 0;
  transition: opacity .5s;
}

.messageclose {
  cursor: pointer;
  vertical-align: middle;
}

.messagebodypair {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (max-width: 420px)  {
  .alertmessageold {
    width: calc(100% - 4rem);
  }
}



/* ------------ Page Not Found ------------- */

.context {
  width: 100%;
  position: absolute;
  top:50vh;
  padding: 0 1rem;

}

.context h1{
  text-align: center;
  color: #304269;
  font-size: 50px;
}

.area{
  background: white;
  width: 100%;
  height:100vh;

}

.squares{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.squares li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(48, 66, 105, 0.9);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.squares li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.squares li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.squares li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.squares li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.squares li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.squares li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.squares li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.squares li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.squares li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.squares li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }
  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }
}

.Pos {
  margin-bottom: 20px;
}