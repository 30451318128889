*, ::after, ::before { -webkit-box-sizing: border-box; box-sizing: border-box; }



/* Loader 1 */
.loader_1 {
	height: 96px;
	width: 96px;
	-webkit-animation: loader_1-1 4.8s linear infinite;
	        animation: loader_1-1 4.8s linear infinite;
}
@-webkit-keyframes loader_1-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_1-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader_1 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 96px;
	width: 96px;
	clip: rect(0, 96px, 96px, 48px);
	-webkit-animation: loader_1-2 1.2s linear infinite;
	        animation: loader_1-2 1.2s linear infinite;
}
@-webkit-keyframes loader_1-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader_1-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
}
.loader_1 span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 96px;
	width: 96px;
	clip: rect(0, 96px, 96px, 48px);
	border: 5px solid #000;
	border-radius: 50%;
	-webkit-animation: loader_1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_1-3 {
	0%   { -webkit-transform: rotate(-140deg); }
	50%  { -webkit-transform: rotate(-160deg); }
	100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader_1-3 {
	0%   { transform: rotate(-140deg); }
	50%  { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
}




/* Loader 2 */
.loader_2 {
  display: block;
	height: 32px;
	width: 32px;
	-webkit-animation: loader_2-1 3s linear infinite;
	        animation: loader_2-1 3s linear infinite;
}
@-webkit-keyframes loader_2-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_2-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader_2 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	clip: rect(16px, 32px, 32px, 0);
	-webkit-animation: loader_2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_2-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_2-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_2-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader_2 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 3px solid transparent;
	border-top: 3px solid #000;
	border-radius: 50%;
	-webkit-animation: loader_2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_2-3 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_2-3 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_2-3 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader_2 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 3px solid rgba(255, 255, 255, .5);
	border-radius: 50%;
}

/* Loader 3 */
.loader_3 {
  display: block;
	height: 32px;
	width: 32px;
}
.loader_3 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
}
.loader_3 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 3px solid #000;
	border-bottom: 3px solid transparent;
	border-radius: 50%;
	-webkit-animation: loader_3-1 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_3-1 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_3-1 {
	0%   { -webkit-transform: rotate(0deg); }
	40%  { -webkit-transform: rotate(180deg); }
	60%  { -webkit-transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_3-1 {
	0%   { transform: rotate(0deg); }
	40%  { transform: rotate(180deg); }
	60%  { transform: rotate(180deg); }
	100% { transform: rotate(360deg); }
}
.loader_3 span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 6px;
	height: 6px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader_3-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_3-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_3-2 {
	0%   { -webkit-transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
	50%  { -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}
@keyframes loader_3-2 {
	0%   { transform: translate3d(0, -32px, 0) scale(0, 2); opacity: 0; }
	50%  { transform: translate3d(0, 0, 0) scale(1.25, 1.25); opacity: 1; }
	100% { transform: translate3d(0, 8px, 0) scale(0, 0); opacity: 0; }
}

/* Loader 4 */
.loader_4 {
	height: 32px;
	width: 32px;
}
.loader_4::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader_4-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_4-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_4-1 {
	0%   { -webkit-transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); opacity: 0; }
}
@keyframes loader_4-1 {
	0%   { transform: scale(0); opacity: 0; }
	50%  { transform: scale(1); opacity: 1; }
	100% { transform: scale(0); opacity: 0; }
}
.loader_4 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	-webkit-animation: loader_4-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_4-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_4-2 {
	0%   { -webkit-transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_4-2 {
	0%   { transform: rotate(0deg); }
	50%  { transform: rotate(180deg); }
	100% { transform: rotate(360deg); }
}
.loader_4 span::before,
.loader_4 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 12px;
	width: 12px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader_4-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_4-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_4-3 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_4-3 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader_4 span::after {
	-webkit-animation: loader_4-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_4-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_4-4 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_4-4 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(16px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

/* Loader 5 */
.loader_5 {
	height: 32px;
	width: 32px;
	-webkit-animation: loader_5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader_5-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader_5::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: auto;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader_5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-2 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(24px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-2 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader_5::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: auto;
	bottom: 0; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader_5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-3 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-24px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-3 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader_5 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
}
.loader_5 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: auto; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader_5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-4 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(0, 24px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-4 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, 24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}
.loader_5 span::after {
	content: "";
	display: block;
	position: absolute;
	top: auto; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 8px;
	height: 8px;
	background: #000;
	border-radius: 50%;
	-webkit-animation: loader_5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader_5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader_5-5 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(0, -24px, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes loader_5-5 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, -24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

/* Loader 6 */
.loader_6 {
	height: 32px;
	width: 32px;
}
.loader_6 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
}
.loader_6 span::before,
.loader_6 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
	border: 2px solid #000;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: loader_6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
	        animation: loader_6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
}
@-webkit-keyframes loader_6-1 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}
@keyframes loader_6-1 {
	0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}
.loader_6 span::after {
	-webkit-animation: loader_6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
	        animation: loader_6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
}
@-webkit-keyframes loader_6-2 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}
@keyframes loader_6-2 {
	0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}

/* Layout */
.center {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}
.wrapper {
	position: relative;
	width: 100%;
	max-width: 1000px;
	height: 100%;
	margin: 0 auto;
}
.wrapper .header {
	text-align: center;
	position: absolute;
	top: 10%; left: 0;
	bottom: auto; right: 0;
	margin: auto;
	letter-spacing: 0.15em;
	z-index: 1;
}
.wrapper .header h1 {
	display: inline-block;
	height: 1em;
	color: #000;
	font-size: 4vh;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	line-height: 1;
}
.wrapper .header p {
	margin-top: 10px;
	color: #000;
	font-size: 1.3rem;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	letter-spacing: 0.065em;
	opacity: .5;
}
.wrapper .header p a {
	color: #000;
	text-decoration: none;
}
.loader-list {
	display: -webkit-flex;
	display:         flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	clear: both;
}
.loader-list li {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	position: relative;
	display: block;
	width: calc(100% / 3);
	height: calc(100vh / 4);
}