.mySelect {
    width: 280px;
    padding: 12px 15px;
    font-size: 18px;
    margin: 5px 0;
    border: 1px solid gray;
    border-radius: 2px;
    transition: 0.3s;
  }

.mySelect:hover {
    cursor: pointer;
    background-color: #E1E1E1;
    transition: 0.3s;
  }
